import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import store from "store";
export default function Protected({ component: Component, ...rest }) {
    console.log('in protected');
    console.log(store.get('auth'));
    if (store.get('auth')) {
        return (
            <Route {...rest} render={(props) => {
                return <Component {...props} />
            }} />
        )
    }
    if (store.get('auth') === undefined) {
        console.log('auth failed');
        return <Redirect to='/login' />
    }
}
