import React, { useContext, useEffect } from 'react'
import styles from './Profile.module.css'
import image from './dude.jpg'
import { Card, ProgressBar } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { IconPickerItem } from 'react-fa-icon-picker'
import { Context } from '../ContextApi/State'
export default function PublicProfile(props) {
    const { get_info, pfp, name } = useContext(Context);
    let { email } = useParams();
    const { get_public_profile, state, Loading, publicProfileError } = useContext(Context);
    const colors = [
        'success',
        'info',
        'warning',
        'danger',
        'primary',
        'secondary',
        'dark'
    ]
    const icon_colors = [
        'green',
        'blue',
        'yellow',
        'purple'
    ]

    useEffect(() => {
        get_public_profile(email);
        get_info();
    }, [])


    if (Loading) {
        return <div className="loader"></div>
    }

    if (publicProfileError) {
        return <div style={{ margin: 'auto', width: '60%' }}>
            <div className="alert mt-5 alert-danger">
                No Public Profile Found!
            </div>
        </div>
    }

    return (
        <div className={`${styles.Body} text-center`} >
            <img className={`${styles.User} mt-5`} src={pfp} />
            <h4 className='mt-4'>{name}</h4>
            {/* <h5 className='text-center mt-5 mb-0'>Achievements</h5> */}
            {state.map((e, i) => {
                return (
                    <div style={{ width: '70%', margin: 'auto', backgroundColor: '#222831' }} className='mt-5 ' key={i}>

                        <Card >

                            <Card.Body className={styles.PublicProfileCard}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div>
                                        <IconPickerItem className='text-center' icon={e.icon_title} size={100} color="#000" />
                                    </div>
                                    <div style={{ flexGrow: '4' }}>
                                        <h4 className='text-left mb-3 mt-3'>{e.title}</h4>
                                        <ProgressBar striped variant={colors[Math.floor(Math.random() * 6)]} label={`${e.scale}%`} now={e.scale} />

                                        <p style={{ paddingBottom: '0' }} className='mt-3 text-left'> {e.description}</p>

                                    </div>
                                </div>
                            </Card.Body>

                        </Card>
                    </div>
                )
            })}

        </div >
    )
}
