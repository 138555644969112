import React, { createContext, useState, useReducer } from 'react'
import { Reducer } from './Reducer';
import axios from 'axios'
import store, { set } from "store";
export const Context = createContext();
//const domain = 'http://localhost:5000';
const domain = 'https://openprofile-api-huestel.herokuapp.com';
export default function State(props) {
    const [initalState, serIntialState] = useState([]);
    const [state, dispatch] = useReducer(Reducer, initalState)
    const [Loading, setLoading] = useState(false);
    const [publicProfileError, setPublicProfilError] = useState(false);
    const [pfp, setPfp] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    //login
    const login = (obj) => {
        //set loading
        setLoading(true);
        var data = JSON.stringify(obj);

        var config = {
            method: 'post',
            url: `${domain}/users/login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                //set loading to false
                setLoading(false);
                //set some ls props
                store.set('auth', 'true');
                store.set('token', response.data.token);
                store.set('username', response.data.name);
                dispatch({ type: "LOGIN_SUCCESSFULL", payload: response.data });
            })
            .catch(function (error) {
                console.log(error);
                //set loading to false
                setLoading(false);
                dispatch({ type: "LOGIN_FAILED", payload: error })
            });

    }

    const register = (obj) => {
        var data = JSON.stringify(obj);
        //set loading
        setLoading(true)
        var config = {
            method: 'post',
            url: `${domain}/users/register`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setLoading(false);
                console.log(JSON.stringify(response.data));
                dispatch({
                    type: "REGISTER_SUCCESSFULL",
                    payload: response.data
                })
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
                dispatch({
                    type: "REGISTER_FAILED",
                    payload: "Error occured!"
                })
            });
    }

    //create a list
    const create_list = (obj) => {
        var data = JSON.stringify(obj);
        setLoading(true)
        var config = {
            method: 'post',
            url: `${domain}/users/create-list`,
            headers: {
                'x-auth-token': store.get('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                dispatch({
                    type: "CREATE_LIST",
                    payload: response.data
                })
                console.log(JSON.stringify(response.data));
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });
    }

    //fetch lists
    const get_lists = () => {
        setLoading(true);
        var config = {
            method: 'get',
            url: `${domain}/users/get-lists`,
            headers: {
                'x-auth-token': store.get('token')
            }
        };

        axios(config)
            .then(function (response) {
                setLoading(false);
                //  console.log(JSON.stringify(response.data.data));
                dispatch({
                    type: "GET_LISTS",
                    payload: response.data.data
                })
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });
    }

    //get lists for a public profile of a user
    const get_public_profile = (email) => {
        setLoading(true)
        var config = {
            method: 'get',
            url: `${domain}/users/public-profile/${email}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                setLoading(false);
                if (response.data.length === 0) {
                    setPublicProfilError(true);
                }
                if (response.data.length > 0) {
                    dispatch({ type: "GET_PUBLIC_PROFILE", payload: response.data })
                    console.log(JSON.stringify(response.data));
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    //upate user profile
    const update_profile = (obj) => {
        setLoading(true);
        var data = JSON.stringify(obj);
        var config = {
            method: 'post',
            url: `${domain}/users/update-profile`,
            headers: {
                'x-auth-token': store.get('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //@Desc Get user information for public profile 
    const get_info = () => {

        var config = {
            method: 'get',
            url: `${domain}/users/my-info`,
            headers: {
                'x-auth-token': store.get('token'),
                'Content-Type': 'application/json'
            },
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                //set user name and pfp for public profile
                //cant dispatch and get using state 
                //state is fetching lists
                setLoading(false)
                setPfp(response.data.profile_link);
                setEmail(response.data.email);
                setName(response.data.name);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    const delete_one = (id) => {
        setLoading(true);
        var config = {
            method: 'get',
            url: `${domain}/users/delete-one/${id}`,
            headers: {
                'x-auth-token': store.get('token')
            }
        };

        axios(config)
            .then(function (response) {
                setLoading(false);
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    //get single list

    const get_single_list = (id) => {
        setLoading(true)
        var config = {
            method: 'get',
            url: `${domain}/users/get-single-list/${id}`,
            headers: {
                'x-auth-token': store.get('token')
            }
        };

        axios(config)
            .then(function (response) {
                setLoading(false)
                dispatch({
                    type: "GET_SINGLE_LIST",
                    payload: response.data
                });
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    const update_single_list = (obj, id) => {
        setLoading(true);
        var data = JSON.stringify(obj);

        var config = {
            method: 'post',
            url: `${domain}/users/edit-list/${id}`,
            headers: {
                'x-auth-token': store.get('token'),
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setLoading(false)
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });

    }

    return (
        <div>
            <Context.Provider value={{ update_single_list, get_single_list, delete_one, get_info, email, name, pfp, update_profile, publicProfileError, state, get_public_profile, get_lists, login, Loading, register, create_list }}>
                {props.children}
            </Context.Provider>
        </div>
    )
}
