import React, { useState, useEffect, useContext } from 'react'
import styles from './Registeration.module.css'
import store from "store";
//import { CONTEXT } from '../ContextApi/State';
import { Redirect, useHistory } from 'react-router-dom';
import { Context } from '../ContextApi/State';
export default function Login() {
    let history = useHistory()
    const { login, state, Loading } = useContext(Context);
    const [disable, setDisable] = useState(false);
    const [user, setUser] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault()

        setDisable(true)
        console.log(user);
        //login user
        login(user)
    }
    const onchange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    console.log(Loading);
    //if auth is successfull it will return a token 
    //if we have a token user should be redirect
    if (state.token) {
        console.log('fly away');
        history.push('/')
    }
    if (Loading) {
        return <div className="loader"></div>
    }
    return (
        <div className={`${styles.Login} container text-center`}>

            <form onSubmit={handleSubmit}>

                <h3 className='mt-4 mb-4'>OpenProfile</h3>

                {state.err &&
                    <div className="alert alert-danger">{state.err}</div>
                }

                <input onChange={onchange} type="text" placeholder='Email' name='email' className="form-control mb-3" />
                <input onChange={onchange} type="password" name='password' placeholder='Password' className="form-control mb-3" />
                <button type='submit' className='mb-3 btn btn-block btn-primary '> Login <i className="fas fa-sign-in-alt"></i> </button>
                <small >Not registered? register <a href="/register">here</a></small>
            </form>
        </div >
    )
}
