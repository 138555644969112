import React, { useContext, useEffect, useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import { Context } from '../ContextApi/State';
import Menu from './Menu'
import { IconPicker } from 'react-fa-icon-picker'

export default function HomePage() {
    const [value, setValue] = useState("")
    const { state, create_list, Loading } = useContext(Context)
    const [input, setInput] = useState('');
    const [err, setErr] = useState(false);

    useEffect(() => {
        if (input['scale']) {
            if (input['scale'] > 100 || input['scale'] < 0) {
                console.log('input is greater than 100');
                setErr(true)
            }
            if (input['scale'] <= 100) {
                setErr(false);
            }
        }
    }, [input])

    const onchange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault()
        if (input['scale'] > 100 || input['scale'] < 0) {
            console.log('input is greater than 100');
            setErr(true);
        }
        if (input['scale'] <= 100) {
            let obj = input;
            obj['icon_title'] = value;
            console.log(obj);
            create_list(obj);
            setErr(false);
        }
        console.log(value);
    }

    console.log(state);
    if (Loading) {
        return <div className="loader"></div>
    }
    return (
        <div>
            <Menu />
            <div className="container text-left mt-5 ">
                <h3 className='mb-3'>  Add List <i className="fas fa-clipboard-list"></i></h3>
                {state.msg &&
                    <div className={`alert alert-${state.type}`}>{state.msg}</div>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Achievement Title</Form.Label>
                        <Form.Control name='title' onChange={onchange} type="text" placeholder="Title i.e Cooking" />
                    </Form.Group>

                    <Form.Label>Choose Icon</Form.Label>
                    <IconPicker value={value} onChange={(v) => setValue(v)} />

                    <Form.Group className="mb-3" controlId="formBasicEmail">

                        <Form.Label className='mt-3'>Achievement Level</Form.Label>
                        <br />
                        {
                            err &&
                            <small style={{ color: 'red' }} className='mb-2 mt-2'>*Please choose level between 0-100</small>
                        }
                        <Form.Control name='scale' onChange={onchange} type="number" maxLength={30} size={30} placeholder="Level i.e 0-100" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Achievement Description</Form.Label>
                        <Form.Control name='description' onChange={onchange} placeholder='Description' as="textarea" maxLength='200' rows={3} />
                    </Form.Group>
                    <div className="text-right">
                        <Button variant="primary" type="submit" >
                            Add
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
