import React, { useContext, useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Menu from './Menu'
import styles from './Main.module.css';
import { Form, Button } from 'react-bootstrap'
import firebase from '../Firebase';
import copy from 'copy-to-clipboard';
import store from "store"
import { Context } from '../ContextApi/State';
export default function PrivateProfile() {
    const [input, setInput] = useState('');
    const [copyLink, setCopyLink] = useState('');
    const [copyText, setCopyText] = useState('Copy Url');
    const [pfp, setPfp] = useState(null);
    const [localTaskLoading, setLocalTaskLoading] = useState(false);
    const { state, update_profile, Loading, get_info, email } = useContext(Context);
    const handleSubmit = async (e) => {
        //if pfp is not empty
        e.preventDefault();
        if (pfp !== null) {
            setLocalTaskLoading(true);
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(pfp.name);
            let uploadTask = await fileRef.put(pfp);
            let profile_link = await fileRef.getDownloadURL();

            let obj = {};
            obj = {
                ...input,
                profile_link
            }
            //set this loading to false
            setLocalTaskLoading(false);
            console.log(obj);
            update_profile(obj);
        }
        //if user chooses not to upload any new picture
        if (pfp === null) {
            console.log(input);
            update_profile(input);
        }
    }

    //get user info
    useEffect(() => {
        get_info();
    }, [])



    const onchange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    };


    //copyProfile link
    const copyProfile = () => {
        copy(`https://hardcore-almeida-c6af15.netlify.app/public-profile/${email}`);
        setCopyLink('URL Copied!');
    }

    
    if (email === '') {
        return <div className="loader"></div>
    }


    if (localTaskLoading === true) {
        return <div className="loader"></div>
    }
    if (Loading === true) {
        return <div className="loader"></div>
    }

    return (
        <div>
            <Menu />
            <Container className='text-left'>
                {state.msg &&
                    <div className={`alert alert-${state.type} mt-2 mt-5`}>{state.msg}</div>
                }

                <h3 className='text-left mt-5 mb-3'>Profile <button onClick={copyProfile} className='btn btn-dark btn-md'>
                    Copy Profile <i className="fas fa-external-link-square-alt btn-dark"></i>
                </button>  <small style={{ fontSize: '15px' }}>{copyLink !== '' ? copyLink : ''}</small>
                </h3>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload profile Picture</Form.Label>
                        <Form.Control onChange={(e) => setPfp(e.target.files[0])} name='profile_picture' type="file" />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Edit Email address</Form.Label>
                        <Form.Control onChange={onchange} name='email' type="email" placeholder="Enter email" />
                        <Form.Text className="text-muted">
                            <small style={{ color: 'white' }}>You'll have to logout and login again if you change your email.</small>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Edit Name</Form.Label>
                        <Form.Control onChange={onchange} name='name' type="text" placeholder="Enter name" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Update
                    </Button>
                </Form>
            </Container>
        </div >
    )
}
