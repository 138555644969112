import React, { useState, useContext, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap';
import { Context } from '../ContextApi/State';
import Menu from './Menu'
import { useParams } from 'react-router-dom';
export default function EditList() {
    const [input, setInput] = useState("");
    const { state, Loading, get_single_list, update_single_list } = useContext(Context)
    const { id } = useParams();
    //get single list and set values
    useEffect(() => {
        get_single_list(id);
    }, []);

    useEffect(() => {
        let obj = {
            title: state.title,
            description: state.description,
            scale: state.scale
        }
        //set the input
        setInput(obj);
    }, [state])



    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(id);
        console.log(input);
        update_single_list(input, id);
    }

    const onchange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    };

    if (Loading) {
        return <div className='loader'></div>
    }

    return (
        <div>
            <Menu />
            <div className="container text-left mt-5 ">
                <h3 className='mb-3'>Edit List</h3>
                {state.msg &&
                    <div className={`alert alert-${state.type}`}>{state.msg}</div>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Achievement Title</Form.Label>
                        <Form.Control defaultValue={input.title} name='title' onChange={(onchange)} type="text" placeholder="Title i.e Cooking" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Achievement Level</Form.Label>
                        <Form.Control name='scale' defaultValue={input.scale} onChange={onchange} type="number" pattern="[0-100]" placeholder="Level i.e 0-100" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Achievement Description</Form.Label>
                        <Form.Control name='description' defaultValue={input.description} onChange={onchange} placeholder='Description' as="textarea" maxLength='200' rows={3} />
                    </Form.Group>
                    <div className="text-right">
                        <Button variant="primary" type="submit" >
                            Update
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
