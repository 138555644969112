import './App.css';
import PublicProfile from './components/Profile/PublicProfile';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './components/Main/HomePage';
// this is whole color pallete used https://colorhunt.co/palette/222831393e4600adb5eeeeee
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Protected from './components/Auth/Protected';
import Login from './components/Register/Login';
import Register from './components/Register/Register';
import State from './components/ContextApi/State';
import Achievements from './components/Main/Achievements';
import Logout from './components/Register/Logout';
import PrivateProfile from './components/Main/PrivateProfile';
import EditList from './components/Main/EditList';
function App() {
  return (
    <div className="App">
      <State>
        <Router>
          <Switch>
            <Route exact path='/public-profile/:email' component={PublicProfile} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/logout' component={Logout} />
            <Protected exact path='/' component={HomePage} />
            <Protected exact path='/achievements' component={Achievements} />
            <Protected exact path='/private-profile' component={PrivateProfile} />
            <Protected exact path='/edit-list/:id' component={EditList} />

          </Switch>
        </Router>
      </State>
    </div>
  );
}

export default App;
