import React from 'react'
import { Redirect } from 'react-router-dom';
import store from "store";
export default function Logout() {

    store.clearAll();

    return <Redirect to='/login' />

}
