export const Reducer = (state, action) => {
    switch (action.type) {
        case "GET_LISTS":
            return action.payload
        case "LOGIN_SUCCESSFULL":
            return action.payload
        case "LOGIN_FAILED":
            return action.payload
        case "REGISTER_SUCCESSFULL":
            return action.payload
        case "REGISTER_FAILED":
            return action.payload
        case "CREATE_LIST":
            return action.payload
        case "GET_PUBLIC_PROFILE":
            return action.payload
        case "GET_SINGLE_LIST":
            return action.payload

    }
}