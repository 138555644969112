import React, { useContext, useEffect } from 'react'
import { Container, Card } from 'react-bootstrap'
import { Context } from '../ContextApi/State'
import Menu from './Menu'
import { Link } from 'react-router-dom';
import loader from './miniLoader.svg';
export default function Achievements() {
    const { state, get_lists, delete_one, Loading } = useContext(Context);

    const delete_list = (e) => {
        let list_id = e.target.dataset.id;
        //delete_one(list_id);
        let done = async () => {
            await delete_one(list_id);
            return true;
        };

        done().then(() => {
            get_lists();
        })
    }

    useEffect(() => {
        get_lists();
    }, [])

    if (Loading) {
        return <div className="loader"></div>
    }

    return (
        <div>
            <Menu />
            <Container>
                <h3 className='text-left mt-5'>Achievements <i className="fas fa-trophy"></i></h3>
                {
                    state.length === 0 &&
                    <div>
                        <img src={loader} alt="Loading" height='350px' width='300px' /> <br />
                        <small>Hmmmm, seems like you dont have anything listed yet?</small>
                    </div>


                }
                {state.map((e, i) => {
                    return (
                        <div key={e._id}>
                            <Card className='mt-3 text-left' style={{ color: 'black' }}>
                                <Card.Body>
                                    <Card.Title>Category: {e.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Rank: {e.scale}</Card.Subtitle>
                                    <Card.Text>
                                        Description: {e.description}
                                    </Card.Text>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <Link to={`/edit-list/${e._id}`} className='btn btn-primary btn-sm'>Edit</Link>
                                        </div>
                                        <div>
                                            <button onClick={delete_list} className='btn btn-danger btn-sm' data-id={e._id}>Delete</button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                })}
            </Container>
        </div>
    )
}
