import firebase from "firebase/app";
import "firebase/storage";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyCz6G4oJiDPnRpXNWHm9ITY5OL6uKIcrew",
    authDomain: "openprofile-d7528.firebaseapp.com",
    projectId: "openprofile-d7528",
    storageBucket: "openprofile-d7528.appspot.com",
    messagingSenderId: "223934622408",
    appId: "1:223934622408:web:567797ee0a17d2799953fd"
  };
firebase.initializeApp(firebaseConfig);
export default firebase;